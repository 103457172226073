import createPlugin from '@@/create-plugin';

export default createPlugin({
    components: {
        'page': [() => import('./components/float'), { priority: 100 }],
        'page:article:after': () => import('./components/after'),
        'page:article:before': () => import('./components/before'),
        'page:article:aside:before': () => import('./components/aside/before'),
        'page:article:aside:after': () => import('./components/aside/after'),
    }
});
